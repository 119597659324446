.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiAccordionSummary-content.Mui-expanded{
  margin: 0 0 !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 0 !important;
}

.flaticon-down-arrow, .flaticon-group, .flaticon-price-tag, .flaticon-business-and-trade, .flaticon-settings-1, .flaticon-folder {
  color: #212121 !important;
}

.MuiAvatar-root {
  width: 25px !important;
  height: 25px !important;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 12px !important;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  line-height: 0px;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  background: none !important;
  color: #202020 !important;
  border: 1px solid #202020 !important;
  margin: -2px 1px 0 1px;
  /* font-weight: 600; */
}

.react-datepicker{
  /* border-left:  1px solid #ccc !important;
  border-right:  1px solid #ccc !important;
  border-bottom:  1px solid #ccc !important;
  border-bottom:  1px solid #ccc !important; */
  border: none !important;
  border-top:  1px solid #aeaeae !important;
  border-radius: 0 !important;
  width: 238px !important;
}

.react-datepicker__header{
  background-color: transparent !important;
}

.react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
  border-width: 1px 1px 0 0 !important;
  border-color: #212121 !important;
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

/**react-contextmenu**/
.react-contextmenu {
  min-width: 90px;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.6;
  padding: 5px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #373a3c;;
  background-color: #eef3f5;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid #ccc;
  padding: 0px;
  margin:0px;
  cursor: inherit;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: '▶';
  display: inline-block;
  position: fixed;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}
/**react-contextmenu**/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*date trange mobile responsive styles*/
.MuiGrid-root .MuiGrid-container .MuiGrid-wrap-xs-nowrap {
  flex-wrap: wrap !important;
  justify-content: center !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.MuiGrid-root ul {
  order: 2 !important;
}

.materialui-daterange-picker-makeStyles-divider-6 {
  border: none !important;
}

.MuiGrid-root .MuiGrid-container .MuiGrid-wrap-xs-nowrap .MuiGrid-justify-content-xs-center {
  flex-wrap: wrap !important;
  order: 3 !important;
}

.MuiGrid-root .materialui-daterange-picker-makeStyles-header-4 .MuiGrid-container .MuiGrid-align-items-xs-center {
  order: 1 !important;
}

@media screen and (min-width: 786px) {
  .materialui-daterange-picker-makeStyles-divider-6 {
    border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
  }
}


