.orgcomp__menu{
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    top: 47px;
    right: 0;
    width: 100%;
    //box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    border:1px solid #DBDBDB !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    overflow: auto;
    height: auto !important;
    max-height: 380px !important;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 15 !important;
  }

.orgcomp__menu::-webkit-scrollbar {
    display: none;
}

.orgcomp__menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.orgcomp__menu.active {
opacity: 1;
visibility: visible;
transform: translateY(0);
}

.orgcomp__menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
.orgcomp__menu li span {
    text-decoration: none;
    color: #333333;
    padding: 10px 16px 10px 16px;
    display: block;
}

.orgcomp__menu li:hover span {
    background:'#ccc !important';
    cursor:'pointer'
}

.org__email {
  padding: 4px 8px !important;
}

.return__email {
  padding: 10px 14px 10px 14px !important;
  border-radius: 4px !important;
  display: flex;
  background-color: #f9f9f9 !important;
  border: 1px solid rgba(0,0,0, 0.1) !important;
}

.comp__dragcontainer {
  width: 100% !important;
 box-sizing: border-box;
}

.comp__dragcontainer {
  // display: block !important;
  // height: 6rem !important;
  // align-items: center;
  // cursor: pointer;
  // border-radius: 4px !important;   
  //margin: 1rem !important;
  //max-width: 610 !important;
}