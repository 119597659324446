.radioGroup{
    // background-color: blue !important;
    font-size: 14px !important;
}

.radioGroup  .MuiFormControlLabel-label {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: black !important;
}

// ruleItemContainer: {
//     marginTop: "10px",
//     marginLeft: "10px",
//     marginRight: "10px",
    
//   },

.ruleItemContainer{
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.ruleItemContainer1{
    margin-top: 10px !important;
    margin-right: 10px !important;
}

.iconsContainer{
    display:  none !important;
}

.ruleItemContainer :hover{
    .iconsContainer{
        display:  block !important;
    }
}

.ruleItemContainer1 :hover{
    .iconsContainer{
        display:  block !important;
    }
}

 
.durationText{
    padding: 5px 0px !important;
    //  max-width: 3rem !important;
    // min-width: 2rem !important;
    // width: auto !important;
    height: 10px !important;
    margin-top: -9px !important ;
    padding-bottom: 0px !important;
    margin-left:  4px !important;
    margin-right: 4px !important;
    width: 6rem !important;
}

.durationText {
    .MuiInputBase-input{ 
        // padding: 5px 15px !important;
        padding: 5px 25px !important;
        }
}
 
.triggerComponentParent{
    .flaticon-delete-1{
        display: none !important;
    }
}

.triggerComponentParent:hover{
    .flaticon-delete-1{
        display: inline-block !important;
    }
}

.actionParent {
    // margin-top: 10px !important;
    // margin-bottom: 10px !important;
    .deletebtn {
        display:  none !important;
    }
}

.actionParent:hover{
    .deletebtn {
        display: flex !important;
        padding-left: 12px !important;
    }
}
 
 
.ruleListContainer{
    max-height: 60vh !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
    width: 100% !important;
  }

  .ruleListContainer::-webkit-scrollbar {
        width: 20px !important;
        background-color: white !important;
  }


.dueDateText{
    padding: 5px 0px !important;
    //  max-width: 3rem !important;
    // min-width: 2rem !important;
    // width: auto !important;
    height: 10px !important;
    margin-top: -12px !important ;
    padding-bottom: 0px !important;
    margin-left:  6px !important;
    margin-right: 6px !important;
    width: 6rem !important;
}

.dueDateText {
    .MuiInputBase-input{ 
        // padding: 5px 15px !important;
        padding: 5px 25px !important;
        }
}