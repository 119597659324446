

.schedulerTitle{
    font-weight: 600 !important;
    font-size: 17px !important;
}

.schedulerTitle2{
    font-weight: 400 !important;
    font-size: 17px !important;
}