.modal__main {
    margin-top: 16px;
    padding: 0px 16px 8px 16px;
    height: calc(100vh - 180px);
    overflow: auto !important;
}

.modal__main1 {
  display: grid;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 0px 16px 8px 16px;
  height: calc(100vh - 180px);
  overflow: auto !important;
}

.modal__main2 {
  //margin-top: 16px;
  height: calc(100vh - 120px);
  overflow: auto !important;
}

.modal__main::-webkit-scrollbar {
  display: none;
}

/**Modal Footer**/
.modal__footer {
    display: flex;
    position: absolute;
    bottom: 51px;
    left: 0;
    right:0;
    padding: 12px 0px 12px 25px;
    z-index:15;
    background: #fff;
    border-top: 1px solid #ccc;
}


.modal__footer1 {
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0;
  right:0;
  padding: 12px 0px 12px 25px;
  z-index:15;
  background: #fff;
  border-top: 1px solid #ccc;
}

.modal__button {
    min-width: 130px !important;
    color: white;
    margin-right: 3% !important;
}

.modal__filter {
    display: flex; 
    position:fixed;
    bottom: 0px;
    left: 64%;
    right:0;
    padding: 12px 0px 12px 25px;
    z-index: 15;
    background: #fff;
    border-top: 1px solid #ccc;
}

/***Status Filters list circle backgrounds**/
.pending__status {
    background: #929292;
  }
  
  .in-process__status {
    background: #FFA834;
  }
  
  .awaiting-reply__status {
    background: #DC4C18;
  }
  
  .pending-approval__status {
    background: #000000;
  }
  
  .completed__status {
    background: #72BB53;
  }
  
  /**Status filter list backgrounds **/
  .pending__background {
    background-color: #f9f9f9;
  }
  
  .in-process__background {
    background-color: rgba(236, 85, 0, 0.1)
  }
  
  .awaiting-reply__background {
    background-color: rgba(219, 76, 63, 0.1);
  }
  
  .pending-approval__background {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .completed__status__background {
    background-color: rgba(59, 150, 27, 0.1);
  }

.status__filterList {
    color: rgba(0, 0, 0, 0.719);
    width: auto;
    margin-bottom: 10px !important;
    margin-right: 5px !important;
    padding: 7px 6px 7px 6px;
    border-radius: 4px ;
    cursor: pointer;
}

.status__filterList.active {
  border: 1px solid #314577;
}

.circle  {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block
  }

.flaticon-filter.active {
  color: #0076ff !important
}  

.filter__dot {
      width: 5.5px;
      height: 5.5px;
      background: #0076ff;
      border-radius: 50%;
      position: absolute;
      top: 2.7px;
      left: 23.5px;
}

.modal__scheduler {
  margin-top: 16px;
  height: calc(100vh - 130px) !important;
  overflow: auto !important;
}

.modal__scheduler::-webkit-scrollbar {
display: none;
}

.modal__schdulerFooter {
  display: flex;
  position: absolute;
  bottom:0;
  left: 0;
  right:0;
  padding: 12px 0px 12px 25px;
  z-index:15;
  background: #fff;
  border-top: 1px solid #ccc;
}

.MuiInputAdornment-root .MuiTypography-colorTextSecondary{
  color: #bbb;
  font-size: 12px;
}

/***Invoice Status Filters list circle backgrounds**/
.not_generated__status {
  background: #FF0000;
}
.draft__status {
  background: #828c98;
}
.viewed__status {
  background: #cca20e;
}
.downloaded__status {
  background: #007bc3;
}
.partial__status {
  background: #006644;
}
.paid__status {
  background: green;
}
.sent__status {
  background: #4c51bf;
}

/***Invoice Status Filters list circle backgrounds**/
.not_generated__background {
  background-color: #ff00003f;
}
.draft__background {
  background-color: #828c982b;
}
.viewed__background {
  background-color: #cca30e48;
}
.downloaded__background {
  background-color: #007cc332;
}
.partial__background {
  background-color: #0066443c;
}
.paid__background {
  background-color: rgba(0, 128, 0, 0.26);
}

.sent__background {
  background-color: rgba(76, 81, 191, 0.1);
}


.active_status {
  background: #72BB53;
}

.in-active_status {
  background: #DC4C18;
}

.max-limit-reached_status {
  background: #FFA834;
}


/**Status filter list backgrounds **/
.active_background {
  background-color: rgba(114, 187, 83, .1);
}

.in-active_background {
  background-color: rgba(220, 76, 24, .1);
}

.max-limit-reached_background {
  background-color: rgba(255, 168, 52, .1);
}

.modal__main3 {
  margin-top: 16px;
  padding: 0px 16px 8px 16px;
  height: calc(100vh - 120px);
  overflow: auto !important;
}

.modal__main3::-webkit-scrollbar {
  display: none;
}