/**Checklist more Action dropdown style**/
.menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}


.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 30px;
  right: 0;
  width: 180px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 15 !important;
}

.menu1{
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 30px;
  right: 0;
  width: 200px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 15 !important;
}

.menu2{
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 30px;
  right: 0;
  width: 200px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 20 !important;
}

.menu.active, .menu1.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu2.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transform: translateX(85%);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 7px 7px;
  display: block;
}

.menu ul li a:hover {
  background:'#ccc';
}

/* Folder overflow ellipsis design*/
.dropdowncontainer {
    position: absolute; 
    top: 56%; 
    left:-275px;
    z-index: 10;
  }
  
  .folderdropdown {
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    border-radius: 5px;
    height: 215px;
    overflow: auto;
    list-style: none;
    padding: 10px 10px 10px 10px;
    width:300px;
    overflow-y: auto; 
    position: absolute;  /* Just apply overflow-y */
  }
  
  .folderdropdown::-webkit-scrollbar {
        width: 20px !important;
   }
  .folderdropdown::-webkit-scrollbar-track {
    background: white;
    width: 30px !important;
    margin-top:5px;
  }
  .folderdropdown::-webkit-scrollbar-thumb {
    border: 8px solid transparent;
    background: #CFCFCF; 
    border-radius: 10px;
    background-clip: padding-box;
  }
  
  .folders {
    display:flex; 
    background:#f4f4f4; 
    border-radius:5px; 
    padding:8px; 
    margin-bottom:6px;
    cursor: pointer;
    color:#202020;
    position: relative;
  }
  
  .dropdownfolderstatus {
    position: fixed;
    z-index: 15;
  
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 31px !important;
}

.checklistItem_link{
  color:  black !important;
  max-width: 32ch !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.checklistBulk__icons {
  color: black !important;
  font-size: 18px !important;
  min-width: 30px !important;
  line-height: 10px !important;
}

/**spinning refresh icon**/
.spinner {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

.spinner:hover {
  background: none !important;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.customMargin {
  margin-top: 2px !important;
  margin-right: 1px !important;
} 

.customMargin2 {
  margin-top: -1px !important;
} 

.bulkmenuStyles {
  padding: 4px 20px; 
  width: 220px;
  overflow:auto; 
  height: auto;
  max-height: 550px; 
}

.bulkmenuStyles::-webkit-scrollbar {
  display: none;
}

.bulkmenuStyles {
  -ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.statusStyle {
  border-radius: 5px;
  padding: 2px 10px 2px 10px;
  margin-top: 4px;
  overflow: hidden;
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
  white-space: nowrap;
  text-overflow: ellipsis
}

//media query for checklist title
@media only screen and (max-width: 1400px) {
  .checklistItem_link{
    max-width: 22ch !important;
  }
}

@media only screen and (max-width: 1280px) {
  .checklistItem_link{
    max-width: 20ch !important;
  }
}

.MuiAutocomplete-fullWidth {
  width: 100% !important;
}

.add_button_style {
  color:  #0071FF !important;
  border: 1px solid #0071FF !important;
}

.statusStyle1 {
  border-radius: 5px;
  width: 100px;
  margin-top: 4px;
  max-width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.statusStyle2 {
  border-radius: 5px;
  width: 150px;
  margin-top: 4px;
  max-width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.profile_menu {
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 50px;
  right: 10px;
  width: 180px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  padding: 8px 0px;
  z-index: 999 !important;
}

.profile_menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.profile_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile_menu li a {
  text-decoration: none;
  color: #333333;
  padding: 7px 10px;
  display: flex;
  align-items: center;
}

.profile_menu ul li:hover a{
  background: #f5f5f5;
}

.iconStyle { 
  width: 1.5em;
  height: 1.5em;
  font-size: 12px;
  position: relative;
  top:4px;
  margin-left: auto;
  flex-shrink: 0;
  user-select: none;
}

.iconStyle1 { 
  width: 1.5em;
  font-size: 16px;
  position: relative;
  top:2px;
  margin-left: auto;
  flex-shrink: 0;
  user-select: none;
}

.generateInvoiceDrawer>.MuiDrawer-paper {
  width: 550px !important;
  // top: 49px !important;
  height: 100% !important;
}

.invoice_icon {
  //color: #666 !important;
  //filter: invert(27%) sepia(100%) saturate(22%) hue-rotate(30deg) brightness(92%) contrast(83%) !important;
}

.invoice_icon.active {
  color: #0076ff !important;
  filter: invert(35%) sepia(47%) saturate(6532%) hue-rotate(203deg) brightness(102%) contrast(107%) !important;
}  