.itemList {
    background-color: #f9f9f9 !important;
    border-radius: 5px 5px 5px 5px;
    transition: none !important;
}

.selectedItemList {
    background-color: #f9f9f9 !important;
    border-radius: 5px 5px 5px 5px;
    transition: none !important;
    border-color: blueviolet !important;
}


.statusStrip {
    width: 5px !important;
    height: 90% !important;
    border-radius: 10px 0px 0px 10px !important;
}

.statusStripDetails {
    width: 5px !important;
    height: 90% !important;
    border-radius: 10px 10px 0px 0px !important;
}

.itemHeading {
    background-color: white !important;
    transition: none !important;
    font-weight: 700 !important;

}

.itemHeadingSelected {
    background-color: #e5efff !important;
    transition: none !important;
    font-weight: 700 !important;

}


.checKlistItemTitle {
    color: "black";
    font-size: 15px;
    cursor: pointer;
}

.titleLineThrough {
    color: "black";
    font-size: 15px;
    text-decoration: line-through;
    cursor: pointer;
}

.dimmed {
    opacity: 0.2 !important;
}


.currentDetail {
    border: 1px solid #d4c7c7 !important;
    border-radius: 5px !important;
    margin-bottom: 1rem !important;

    .MuiAccordion-root.Mui-expanded {
        margin: 10px 10px !important;
    }
}


.main {
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 10px;

    .MuiAccordion-root.Mui-expanded:last-child {
        margin-bottom: 1rem !important;
    }

    .MuiButton-root:hover {
        text-decoration: none;
        background-color: inherit !important;
    }
}

.selectedMain {
    border-radius: 10px 5px 5px 5px;
    margin-bottom: 10px;
    border: 2px solid rgba(0, 0, 0, 0.08) !important;

    .MuiAccordion-root.Mui-expanded:last-child {
        margin-bottom: 1rem !important;
    }

    .MuiButton-root:hover {
        text-decoration: none;
        background-color: inherit;
    }
}