.attachment-link {
    margin-right: 8px;
    padding: 10px;
    border: 1px solid #aaa;
    border-radius: 50px;
    margin-bottom: 18px;
    text-decoration: none !important;
    display: flex !important;
    align-items: center !important;
    color: #202020;
    cursor: pointer;
}

a {
    text-decoration: none;
}

.icon_style {
    width:18px;
    height: 18px;
    
}

.rdw-editor-main blockquote {
    border-left: 1px solid #555 !important;
    padding-left: 5px !important;
}

.tox {
    box-sizing: content-box;
    color: #222f3e;
    cursor: auto;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    vertical-align: initial;
    white-space: normal;
    border:none !important;
}

.tox-tinymce {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    position: relative;
    visibility: inherit !important;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(238, 238, 238, 1);
    border-image: initial;
    border-radius: 10px;
    overflow: auto !important;
}

.tox .tox-editor-container {
    display: flex !important;
    flex-direction: column !important;
    overflow: auto !important;
}

.tox .tox-edit-area {
    height: 300px !important;
    order: 1;
}

.tox .tox-editor-header {
    order: 2 !important;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.20) !important;

}

.tox .tox-statusbar {
    display: none !important;
}


.jodit-container {
    box-sizing: content-box;
    color: #222f3e;
    cursor: auto;
    box-sizing: content-box;
    color: #222f3e;
    cursor: auto;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    vertical-align: initial;
    border:none !important;
}

.jodit-wysiwyg_mode {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    visibility: inherit !important;
    overflow: auto !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: auto !important;
    position: relative !important;
}

.jodit-workplace{
    order: 1 !important;
    line-height: 1.5 !important;
}

.jodit-toolbar__box {
    order: 2 !important;
    width: 100% !important;
    outline: none !important;
    border-bottom: 1px solid #fff !important;
    border-top: 2px solid rgba(0,0,0,0.12) !important;
    box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12), 0px -2px 4px -1px rgba(0, 0, 0, 0.20) !important; /* Top shadow */
}

.jodit-status-bar {
    display: none !important;
}

.jodit-editor__resize {
    display: none !important;
}

.jodit-toolbar__box:not(:empty) .jodit-toolbar-editor-collection:after {
    background-color: #fff !important;
}

.jodit-toolbar-editor-collection .jodit-toolbar-editor-collection_mode_horizontal .jodit-toolbar-editor-collection_size_middle input:nth-child(-1) {
    display: none !important;
}