.detailsTaskLeft {
    position: relative;
    overflow: hidden !important;
    overflow-y: scroll !important;
    // height: 72vh !important;
    height: 79vh !important;
    max-width: 100vw !important;
}

.detailsTaskLeft::-webkit-scrollbar {
    width: 20px;
    background-color: white;
    // background-color: green!important;
}

.listContainer {
    height: 70vh !important;
    // margin-right: -2rem !important;
    // margin-right: -8rem !important;
    margin-left: 15px !important;

}

.detailsTaskRight {
    overflow: hidden !important;
    overflow-y: scroll !important;
    // height: 72vh !important;
    height: 79vh !important;
    padding-bottom: 10px !important;
}

.detailsTaskRight::-webkit-scrollbar {
    width: 20px;
    background-color: white;
}

.dragcontainer {
    width: 550px !important;
    border: none !important;
    outline: none !important;
}

.dragcontainer>label {
    display: block !important;
    height: 8rem !important;
    align-items: center;
    border: dashed 2px #c5bebe !important;
    cursor: pointer;
    margin: 1rem !important;
    max-width: 610 !important;
    margin-bottom: -2px !important;
    font-size: 10px !important;
}

.drawerContainer>.MuiDrawer-paper {
    width: 550px !important;
    // top: 49px !important;
    height: 100% !important;
}

.drawerContainerSchedular>.MuiDrawer-paper {
    width: 820px !important;
    // top: 49px !important;
    height: 100% !important;
    overflow-x: hidden !important;
}

//  File Upload
.customFileUpload> ::-webkit-file-upload-button {
    color: #656565 !important;
    border: 1px solid #656565 !important;
    margin: 10px 10px 10px 0px !important;
    padding: 5px 10px 5px 10px !important;
    border-radius: 5px !important;
    font-size: 0.875rem !important;
    background-color: white !important;
}

.customFileUpload> ::-webkit-file-upload-button:target {
    border-color: rgba(0, 0, 0, 0.404) !important;
}

// font-size: 12px;
// color: #5e6265;
// font-weight: 600;
// padding: 0px;
// background-color: grey;
// border: none !important;



// Activities
.mentions {
    margin: 1em 0;
    height: 7rem !important;
    font-size: 15px !important;
}

.mentions--singleLine .mentions__control {
    display: inline-block;
}

.mentions--singleLine .mentions__higlighter {
    padding: 1px;
    border: 2px inset transparent;

}

.mentions--singleLine .mentions__input {
    padding: 5px;
    border: 2px inset;
}

.mentions--multiLine .mentions__control {
    // font-family: monospace;
    // font-size: 14pt;
    border: 1px solid silver;
    height: 7rem !important;
    border-radius: 5px !important;
}

.mentions--multiLine .mentions__highlighter {
    padding: 9px;
}

.mentions--multiLine .mentions__input {
    padding: 9px;
    min-height: 63px;
    outline: 0;
    border: 0;
}

.mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding: 2px !important;
    font-weight: 600 !important;
}

.mentions__suggestions__item {
    padding: 5px 15px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
    background-color: silver !important;
}

.mentions__mention {
    background-color: #E8F5FB;
}


// checklist Status

.commentTiming {
    margin-top: -20px !important;
    font-size: 11.5px !important;
    font-weight: 500 !important;
    color: #6c757d !important;
}

.activityTiming {
    margin-top: -20px !important;
    font-size: 11.5px !important;
    font-weight: 500 !important;
    color: #6c757d !important;
}

.controlBtnEdit {
    background-color: white !important;
    font-size: 11.5px !important;
    font-weight: 600 !important;
    padding: 0px !important;
    margin-right: -6px !important;
    margin-left: 10px !important;
    color: #6c757d !important;
}

.controlBtnDelete {
    background-color: white !important;
    font-size: 11.5px !important;
    font-weight: 600 !important;
    padding: 0px !important;
    margin-left: -15px !important;
    color: #6c757d !important;
}

.checklistStatus {
    background-color: white !important;
    text-decoration: none !important;
    border: none !important;
    border-color: white !important;
    outline: none !important;
    width: 144px !important;
    margin-left: -4px !important;
    margin-right: 3px !important;
}

// Topbar labels
.companyTitle {
    margin-left: 4px !important;
}

.companyTitle2 {
    margin-left: 4px;
    margin-top: 12px !important;
}

// detailsLabels: {
//     // display:'flex',
//     marginTop: "-9.5px",
//     // flexGrow:'1',
//     "& .labellist:last-child": {
//       "& .bullet": {
//         display: "none",
//       },
//     },
//   },
//   detailsLabels2: {
//     // display:'flex',
//     marginTop: "-19.5px",
//     // flexGrow:'1',
//     "& .labellist:last-child": {
//       "& .bullet": {
//         display: "none",
//       },
//     },
//   },

.detailsLabels {
    // margin-top: -9.5px !important;
    margin-top: -9.6px !important;

    .labellist:last-child {
        .bullet {
            display: none !important;
            // background-color: blue !important;
        }
    }
}


.details_labels {
    margin-top: -19.5px !important;

    .labellist:last-child {
        .bullet {
            display: none !important;
            background-color: blue !important;
        }
    }
}

.checklistDetailStatus {
    border-radius: 5px !important;
    padding: 1px 5px !important;
    color: #656565 !important;
    border: 1px solid #656565;
    width: 10rem !important;
    font-size: 10px !important;
}

.checklistDetailStatus {
    background-color: white !important;

    .MuiSelect-icon {
        margin-right: -1rem !important;
    }

    .MuiSelect-selectMenu {
        margin-left: 0.5rem !important;
    }

    .MuiSelect-select:focus {
        border-radius: 0;
        background-color: white !important;
    }

    .MuiAccordionDetails-root {
        display: flex;
        padding: 16px 16px 16px !important;
    }

    .MuiButton-root:hover {
        text-decoration: none;
        background-color: white !important;
    }

}

// moreList: {
//     borderBottom: "8px",
//     "&:hover": {
//       background: "#d3d3d3",
//       borderBottom: "8px",
//     },
//   },
//   moreListDisabled:{
//     color:"red"
//   }

.moreList {
    border-bottom: 8px;
}

.moreList>a {
    color: black !important;
    border-bottom: 8px;
}

.moreList:hover {
    background-color: #d3d3d3;
    border-bottom: 8px;
}

.moreListDisabled {
    pointer-events: none !important;
}

.moreListDisabled>a {
    color: #d3d3d3 !important;
    pointer-events: none !important;
    // display: none !important;
}

// diabled 
.flaticonPriceTagDisabled {
    color: rgba(0, 0, 0, 0.26) !important;
    pointer-events: none !important;
}

.activityItemParent {
    margin-bottom: 3px !important;
    margin-top: 3px !important;
}

.activityItemParent {
    #activityIcons {
        color: white !important;
    }
}

.activityItemParent {
    .horizontalDivider {
        width: 1px !important;
        background-color: #cacaca !important;
        margin-top: 0px !important;
        border: none !important;
        margin-bottom: 0px !important;
        margin-left: 15px !important;
    }
}

.activityItemParent:last-child {
    .horizontalDivider {
        display: none !important;
    }
}


.commentsParent {
    .horizontalDivider {
        width: 1px !important;
        background-color: #cacaca !important;
        margin-top: 0px !important;
        border: none !important;
        margin-bottom: 0px !important;
        margin-left: 15px !important;
    }
}

.commentsParent>.commentsChild:last-child {
    .horizontalDivider {
        display: none !important;
    }
}

//-----------------email scheduler mention overridden styling-----------
.mentionWrapper {
    //z-index: 999 !important;
    position: relative;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input {
    width: 100%;
    //background: transparent;
    //font-size: 0.9rem;
    color: gray; // #a9b5c4;
    margin: 4px auto; //8px;
    border: 1px solid rgb(219, 219, 219) !important;
    padding: 8px 0px 8px 12px !important;
    border-radius: 5px !important;
    position: relative;
}

.mentionWrapper--singleLine .mentionWrapper__higlighter {
    padding: 1px;
    border: 2px inset transparent;

}

.mentionWrapper--multiLine .mentionWrapper__control {
    // font-family: monospace;
    // font-size: 14pt;
    border: 1px solid silver;
    height: 7rem !important;
    border-radius: 5px !important;

}

.mentionWrapper--multiLine .mentionWrapper__highlighter {
    padding: 9px;
}

.mentionWrapper--multiLine .mentionWrapper__input {
    padding: 9px;
    min-height: 63px;
    outline: 0;
    border: 0;
    margin-left: 16px;
}
.mentionWrapper__suggestions{
    z-index: 999 !important;
}
.mentionWrapper__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding: 2px !important;
    font-weight: 600 !important;
}

.mentionWrapper__suggestions__item {
    padding: 5px 15px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentionWrapper__suggestions__item--focused {
    background-color: silver !important;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input::placeholder {
    color: #ccc; //#7288a3;
    font-weight: 400;
    font-size: 14px;
}


.mentionWrapper .mentionWrapper__control {
    height: 30px !important;
    min-height: 30px !important;
    border: none;
    z-index: 999 !important;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__highlighter {
    padding: 0.7rem 1rem;
    background-color: white;
    max-height: 100% !important;
}

.rdw-suggestion-dropdown {
    width: 350px;
    border-radius: 5px !important;
    position: absolute;
    left: auto !important;
    right: auto !important;
}

.rdw-suggestion-dropdown .rdw-suggestion-option:hover {
    cursor: pointer;
    background-color: silver !important;
}

//-----------------email scheduler mention overridden styling-----------  
//editor schedulor editor
.public-DraftStyleDefault-block {
    margin: 0;
}

.time_style {
    color: grey;
    font-size: 13px;
}