 .attachmentsListItem {
     margin-top: 5px !important;
     background-color: #f9f9f9 !important;
     padding-top: 5px !important;
     padding-bottom: 5px !important;
 }

 .attachmentsIcons {
     display: none !important;
 }

 .attachmentParent {
     background-color: red !important;
 }

 .attachmentParent :hover {
     .attachmentsIcons {
         display: flex !important;
     }
 }
 